import React from "react";
import { useRef } from "react";
import { createUseStyles, useTheme } from 'react-jss';
import { useStaticQuery, graphql } from "gatsby";

import Image from './image';

const useStyles = createUseStyles(theme => ({
  footerRoot: {
    position: 'relative',
    background: 'rgba(0, 0, 0, 0.85)'
  },
  footerBgImage: {
  },
  footerContent: {
    ...theme.layout.content,
    height: '100%',
    padding: theme.layout.spacing.standard,
    boxSizing: 'border-box',
    color: theme.colors.white,
    width: '100%',
  },
  footerContentText: {
    width: '100%',
    padding: '1rem',
    boxSizing: 'border-box',
  },
  horSeperator: {
    margin: [0, theme.layout.spacing.standard]
  }
}));

const Footer = () => {
  const theme = useTheme()
  const classes = useStyles({ theme });
  const data = useStaticQuery(graphql`
    query FooterQuery {
      strapiSite {
        about_header
        about
      }
      bgImage: file(name: {eq: "footer-bg"}) {
        id
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <footer className={classes.footerRoot}>
      <Image
        className={classes.footerBgImage}
        image={data.bgImage}
        isBackground
      />
      <div className={classes.footerContent}>
        <div className={classes.footerContentText}>
          <h2>
            {data.strapiSite.about_header}
          </h2>
          <p>
            {data.strapiSite.about}
          </p>
          <p>
            <span>{`©\u00A0${new Date().getFullYear()}`}</span>
            <span className={classes.horSeperator}>|</span>
            <span>foo@alternativa&#8209;burgare.se</span>
          </p>
        </div>
      </div>
    </footer >
  );
}

Footer.propTypes = {
}

export default Footer
