import React from "react"
import { ThemeProvider } from 'react-jss'
import PropTypes from "prop-types"

import Header from "./header"
import Footer from "./footer"

import 'normalize.css'
import './base.css'

const Layout = ({ children }) => {
  return (
    <React.Fragment>
      <Header />
      <main>
        {children}
      </main>
      <Footer />
    </React.Fragment>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout