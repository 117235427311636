import React from "react"
import PropTypes from "prop-types";
import Img from "gatsby-image"

const Image = ({ image, className, isBackground }) => {

  if (!image?.childImageSharp?.fluid) {
    return null;
  }

  return (
    <Img
      className={className}
      fluid={image?.childImageSharp?.fluid}
      imgStyle={!isBackground ? { objectFit: 'contain' } : undefined}
      style={isBackground ? {
        position: "absolute",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        zIndex: -100
      } : undefined}
    />
  );
}

Image.propTypes = {
  image: PropTypes.object.isRequired,
  className: PropTypes.string,
  isBackground: PropTypes.bool
}

Image.defaultProps = {
  isBackground: false
}

export default Image
