import React from "react";
import { useRef } from "react";
import { createUseStyles, useTheme } from 'react-jss';
import { useStaticQuery, graphql } from "gatsby";

import BackgroundImage from 'gatsby-background-image'
import Image from './image';

const useStyles = createUseStyles(theme => ({
  headerRoot: {
    position: 'relative',
    background: 'rgba(0, 0, 0, 0.65)'
  },
  headerBgImage: {
  },
  headerContent: {
    ...theme.layout.content,
    height: '100%',
    padding: ['12rem', theme.layout.spacing.standard],
    boxSizing: 'border-box',
    color: theme.colors.white,
    width: '100%',
  },
  headerContentText: {
    width: '100%',
    //backgroundColor: 'rgba(0, 0, 0, 0.35)',
    padding: '1rem',
    boxSizing: 'border-box',
    //textShadow: '2px 2px 2px black',
    [theme.layout.responsive.twoColumnsQuery]: {
      width: 'calc(50% - 1rem)'
    }
  },
  title: {
  },
}));

const Header = () => {
  const theme = useTheme()
  const classes = useStyles({ theme });
  const data = useStaticQuery(graphql`
    query SiteQuery {
      strapiSite {
        title
        subtitle
        short_intro
      }
      bgImage: file(name: {eq: "header-bg2"}) {
        id
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const bgRef = useRef();

  /*
      <BackgroundImage
        className={classes.headerBgImage}
        fluid={data.bgImage.childImageSharp.fluid}
        ref={bgRef}
        onStartLoad={() => bgRef.current.selfRef.classList.toggle(classes.headerBgImageLoading)}
        onLoad={() => bgRef.current.selfRef.classList.toggle(classes.headerBgImageLoading)}
        imgStyle={{

        }}
      >

      </BackgroundImage>
  */

  return (
    <header className={classes.headerRoot}>
      <Image
        className={classes.headerBgImage}
        image={data.bgImage}
        isBackground
      />
      <div className={classes.headerContent}>
        <div className={classes.headerContentText}>
          <h1 className={classes.titleFirst}>
            Alternativa
            <br />
            Burgare
          </h1>
          <h2>
            {data.strapiSite.subtitle}
          </h2>
          <p>
            {data.strapiSite.short_intro}
          </p>
        </div>

      </div>
    </header >
  );
}

Header.propTypes = {
}

export default Header
